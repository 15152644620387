<template>
	<v-card
		class="mx-auto v-card--material pa-3"
		elevation="0"
		:loading=loading
	>
		<base-material-card
			:icon="icon"
			:title="title"
			:color="color"
		>
		<span class="font-weight-medium">{{ subtitle }}</span>
			<v-row>
				<template v-for="(item, i) in totales">
					<v-col cols="12" sm="2">
						<base-total-card :item="item" />
					</v-col>
				</template>
			</v-row>

		</base-material-card>
	</v-card>
</template>

<script>
	import BaseMaterialCard from '@/components/base/MaterialCard.vue'
	import BaseTotalCard from '@/components/base/TotalCard.vue'
	export default {
		name: 'TotalIcon',
		props: {
      registros: {
        type: Array,
      },
			title: {
				type: String,
			},
			icon: {
				type: String,
			},
			color: {
				type: String,
			},
			subtitle: {
				type: String,
			},
    },
		components: {
			BaseTotalCard,
			BaseMaterialCard
		},
		data: () => ({
			loading: false,
			totales: [],
		}),
		mounted(){
			this.setTotales()
		},
		methods: {
			async setTotales() {
				var totalMiembros=0
				var totalNoMiembros=0
				var otrasEspecialidades=0
				var estudiantes=0
				var industria=0

				let me=this

				if (me.registros != null) {
					var usuarios = await me.registros.map( function(registro) {

						if (registro.categoria == 'Dermatologo Miembro'){
							totalMiembros++
						}
						if (registro.categoria == 'Dermatologo No-Miembro'){
							totalNoMiembros++
						}
						if (registro.categoria == 'Otras Especialidades'){
							otrasEspecialidades++
						}
						if (registro.categoria == 'Estudiante'){
							estudiantes++
						}
						if (registro.categoria == 'Industria'){
							industria++
						}

					})
				}

				this.totales = [
					{
						color: 'grey',
						icon: 'mdi-account-group',
						grupo: 'Total',
						cantidad: (this.registros && this.registros.length ) ? this.registros.length : 0

					},
					{
						color: 'grey',
						icon: 'mdi-account-circle',
						grupo: 'Miembros',
						cantidad: totalMiembros

					},
					{
						color: 'grey',
						icon: 'mdi-account-circle-outline darken-1',
						grupo: 'No Miemb.',
						cantidad: totalNoMiembros

					},
					{
						color: 'grey',
						icon: 'mdi-account-convert',
						grupo: 'Otra Especi.',
						cantidad: otrasEspecialidades

					},
					{
						color: 'grey',
						icon: 'mdi-account-settings-outline',
						grupo: 'Estudiantes',
						cantidad: estudiantes

					},
					{
						color: 'grey',
						icon: 'mdi-account-cog-outline',
						grupo: 'Industria',
						cantidad: industria

					},
				]

			},

		},
		computed: {

		}
	}
</script>

<style lang="scss" scoped>

</style>