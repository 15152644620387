<template>
	<v-card
		class="mx-auto v-card--material pa-3"
		elevation="0"
		:loading=loading
		v-if="registro != null"
	>
		<base-material-card
			:icon="icon"
			:title="title"
			:color="color"
		>
		<span class="font-weight-medium">{{ subtitle }}</span>
			<v-row>
				<v-col cols="12" sm="2">
					<base-total-card :item="{
						color: 'grey',
						icon: 'mdi-account-group',
						grupo: 'Total',
						cantidad: registro.total
					}" />
				</v-col>
				<v-col cols="12" sm="2">
					<base-total-card :item="{
						color: 'grey',
						icon: 'mdi-account-circle',
						grupo: 'Miembros',
						cantidad: registro.totalMiembros
					}" />
				</v-col>
				<v-col cols="12" sm="2">
					<base-total-card :item="{
						color: 'grey',
						icon: 'mdi-account-circle-outline darken-1',
						grupo: 'No Miemb.',
						cantidad: registro.totalNoMiembros
					}" />
				</v-col>
				<v-col cols="12" sm="2">
					<base-total-card :item="{
						color: 'grey',
						icon: 'mdi-account-convert',
						grupo: 'Otra Especi.',
						cantidad: registro.otrasEspecialidades
					}" />
				</v-col>
				<v-col cols="12" sm="2">
					<base-total-card :item="{
						color: 'grey',
						icon: 'mdi-account-settings-outline',
						grupo: 'Estudiantes',
						cantidad: registro.estudiantes
					}" />
				</v-col>
				<v-col cols="12" sm="2">
					<base-total-card :item="{
						color: 'grey',
						icon: 'mdi-account-cog-outline',
						grupo: 'Industria',
						cantidad: this.registro.industria
					}" />
				</v-col>
			</v-row>
		</base-material-card>
	</v-card>
</template>

<script>
	import BaseMaterialCard from '@/components/base/MaterialCard.vue'
	import BaseTotalCard from '@/components/base/TotalCard.vue'
	export default {
		name: 'TotalIcon',
		props: {
      registro: {
        type: Object,
      },
			title: {
				type: String,
			},
			icon: {
				type: String,
			},
			color: {
				type: String,
			},
			subtitle: {
				type: String,
			},
    },
		components: {
			BaseTotalCard,
			BaseMaterialCard
		},
		data: () => ({
			loading: false,
			totales: [],
		}),

		methods: {},
		computed: {}
	}
</script>

<style lang="scss" scoped>

</style>