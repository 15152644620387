<template>
<v-container>
	<h3>{{acum}}/{{ users.length }} Registros a procesar</h3>
	<div v-if="!loaded">
		<progress-component />
	</div>
	<table v-else>
		<tr v-for="item in users" :key="item._id">
			<td>{{ item._id }}</td>
			<td>{{ item.apellido }} {{ item.nombre }}</td>
			<td>{{ item.email }}</td>
			<td>{{ (item.total) ? item.total : '0' }}</td>
			<td>
				<div>
					<v-icon color="red" @click.prevent="eliminar(item)">
						mdi-delete
					</v-icon>
				</div>
			</td>
		</tr>
	</table>

</v-container>
</template>

<script>
	import axios from 'axios';
	import store from '@/store'
	import ProgressComponent from '@/components/base/Progress.vue'
	const header = {"Token": store.state.userModule.token}
	const configuration = {headers: header}

	export default {
		components: {
			ProgressComponent,
		},

		data: () => ({
			users: [],
			usersFilter: [],
			loaded: false,
			acum: 0
		}),

		mounted(){
			this.getData()
		},

		methods: {
			eliminar(item){
				console.log(item)
				let me=this
				axios.post('user/remove', { _id: item._id, }, configuration)
				.then(function(response){
					me.$store.commit('SET_ALERT_MESSAGE', {
						show: true,
						color: 'success',
						message: 'Usuario eliminado satisfactoriamente',
						timeout: 2000
					}, {root: true})
				})
				.catch(function (e) {
					console.log(e.message)
				})
			},
			async getData(){
				try {
					const {data} =  await axios.get('user/list', configuration);
					this.users = await data.filter( x => x.categoria == 'Estudiantee' );
					let resp = null
					for (let index = 0; index < this.users.length; index++) {
						const user = this.users[index];
						resp = await axios.get(`inscripcion/get-user?user=${user._id}` ,configuration)
						const code = resp.data.code
						if ( code && code == '200') {
							 this.users[index].total = resp.data.reg.length
						}
						this.acum = this.acum+1
					}
					this.loaded = true

				} catch (error) {
					console.log(error)
				}
			},
		}


	}

</script>

<style scoped>

	td {
		padding: 10px;
	}
</style>