<template>
  <v-card
		color='transparent elevation-0'
		light
		class="v-card--plan mx-auto pt-3 pb-4 px-2 text-center"
		max-width="100%"
	>
		<v-avatar
			size="100"
		>
			<v-icon
				:color='item.color'
				size="64"
				v-text="item.icon"
			/>

		</v-avatar>

		<h2
			class="display-1 font-weight-light"
			v-text="item.cantidad"
		/>

		<v-card-text
			class="body-1 font-weight-light pa-1"
			v-text="item.grupo"
		/>
		</v-card-text>

	</v-card>
</template>

<script>
  export default {
    name: 'TotalCard',

    props: {
      item: {
        type: Object,
        default: () => ({
          color: undefined,
          icon: undefined,
          cantidad: 0,
          grupo: undefined,
        }),
      },
    },
  }
</script>

<style lang="sass">
  .v-card--plan
    .v-avatar
      border-radius: 50%
      border: 1px solid #E5E5E5
</style>
